<template>
  <Card :image-src="'/images/stay-detail-default-hero.jpg'" v-if="ready">
    <div class="text-center">
      <h3 class="text-2xl font-medium text-slate-800 tracking-wide">Find your stay</h3>
      <p class="text-gray-600 mt-2">Add your 7 letter reference code to start check-in</p>
      <div class="max-w-md mx-auto mt-2">
        <div class="flex justify-center">
          <ReferenceCodeInput :error="!!claimError" />
          <SButton theme="ghost" square @click="onReset" v-if="claimError" v-auto-animate>
            <phosphor-icon-arrow-counter-clockwise />
          </SButton>
        </div>
        <p class="mt-2 text-sm text-alert-danger" v-if="claimError">{{ claimError }}</p>
      </div>
    </div>

    <template #footer>
      <SButton :disabled="!canFindStay" class="text-md w-full h-14" @click="onFindStay">Find stay</SButton>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { MP_CLAIMED_BY_REF } from '~/plugins/mixpanel.client';
const { referenceCode, claimError, isFetchingClaim } = storeToRefs(useWebsiteStore());
const { claimByReferenceCode } = useWebsiteStore();
const { $mixpanel } = useNuxtApp();

const ready = ref(false);
onMounted(() => { ready.value = true; });
watch(() => referenceCode.value, () => {
  if (!codeContainsNumbers.value) claimError.value = null;
});

const codeContainsNumbers = computed(() => referenceCode.value.match(/\d+/g));

const canFindStay = computed(() => {
  return referenceCode.value.length === 7 && !isFetchingClaim.value && !claimError.value;
});

const onReset = () => {
  referenceCode.value = "";
  claimError.value = null;
};

const onFindStay = async () => {
  if (!referenceCode.value) return;
  claimError.value = null;
  await claimByReferenceCode(referenceCode.value);
  $mixpanel(MP_CLAIMED_BY_REF, { referenceCode: referenceCode.value });
};
</script>
